// img
import hero02 from '../../assets/images/hero-02.png'

import vector01 from '../../assets/images/Vector1.svg'
import vector02 from '../../assets/images/Vector2.svg'

const HeaderBanner = () => {
  return (
    <div className='container'>
      {/* <!--========header-banner===============--> */}
      <div className='header-banner-wrapper'>
        {/* <!--header-banner-right--> */}
        <div className='header-banner-right'>
          {/* <!--main-text--> */}
          <h1 className='heading-primary'>
              جدیدترین برندهای مکمل  و پروتئین
            <span className='heading-primary-char'>
             
              <img
                src={vector01}
                alt='img'
                className='heading-primary-circle'
              />
            </span>
            با بهرین قیمت ها را با ما تجربه کنید
          </h1>
          {/* <!--text--> */}
          <p className='heading-primary-sub-text'>
            {/* <!--line--> */}
            <span className='sub-text-line'></span>
            {/* <!--text--> */}
            بهترین و متنوع ترین فروشگاه مکمل غذایی
          </p>
          {/* <!--header-button--> */}
          <a href='./shop.html' className='hero-buy-btn'>
            {/* <!--text--> */}
            <span className='hero-buy-btn-text'>دیدن محصولات</span>
            {/* <!--arrow-circle--> */}
            <span className='hero-buy-btn-arrow'>
              {/* <!--icon--> */}
              <i className='fa-solid fa-arrow-left hero-buy-btn-icon'></i>
              <span className='hero-but-btn-arrow-span'>.</span>
            </span>
          </a>
          <img src={vector02} alt='img' className='hero-wave' />
          {/* <!--bottom-wrapper--> */}
          <div className='hero-bottom-wrapper'>
            {/* <!--text--> */}
            <p className='hero-bottom-text'>
              {/* <!--hero-bottom-span--> */}
              <span className='hero-bottom-text-span'>1.8M</span>
              {/* <!--hero-bottom-span--> */}
              <span className='hero-bottom-main-text'>کاربر آنلاین</span>
            </p>
            {/* <!--text--> */}
            <a href='./dashboard.html' className='hero-bottom-link'>
              ورود به داشبورد
              <i className='fa-solid fa-arrow-left'></i>
            </a>
          </div>
        </div>
        {/* <!--header-banner-left--> */}
        <div className='header-banner-left'>
          {/* <!--hero-wrapper--> */}
          <div className='hero-wrapper'>
            {/* <!--sub--circle--> */}
            <div className='hero-sub-circle background-light hero-circle making-center-position'></div>
            {/* <!--main-circle--> */}
            <div className='hero-main-circle making-center-position'></div>
            {/* <!--img--> */}
            <div className='hero-img-wrapper'>
              <img src={hero02} alt='img' className='header-hero-img' />
            </div>
            {/* <!--glass--> */}
            <div className='hero-glass making-center-position'>
              {/* <!-- circle --> */}
              <span className='glass-circle'></span>
              {/* <!-- circle --> */}
              <span className='glass-circle'></span>
              {/* <!--text--> */}
              <p className='hero-glass-text'>WHEY</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderBanner
