
import news01 from '../../assets/images/news/01.png'
import news02 from '../../assets/images/news/03.png'
import news03 from '../../assets/images/news/02.png'
const News = () => {
  return (
    <div>
      {/* <!--===========news-section=========--> */}
      <section className='news-section'>
        {/* <!--background-lights--> */}
        <div className='background-light news-background-light suggestion-background-light'></div>
        {/* <!--setting-container--> */}
        <div className='container'>
          {/* <!--main-wrapper--> */}
          <div className='news-section-content-wrapper'>
            {/* <!--title--> */}
            <h4 className='section-title'>
              <span className='section-main-tilte'> اخبار جدید </span>
              <span className='section-sub-title'>
                جدیدترین اخبار دنیای مکمل را با ما دنبال کنید
              </span>
            </h4>
            {/* <!--news-boxes-wrapper--> */}
            <div className='news-boxes-wrapper'>
              {/* <!--box--> */}
              <a href='#' className='news-box-link'>
                <div className='news-box'>
                  {/* <!--img-wrapper--> */}
                  <div className='news-img-wrapper'>
                    <img src={news01} alt='img' className='news-img' />
                  </div>
                  {/* <!--caption--> */}
                  <div className='news-caption'>
                    {/* <!--date-and-writer-name--> */}
                    <p className='news-date-and-owner'>
                      <span className='news-date'>12 فروردین ,1401</span>
                      از
                      <span className='news-owner'>سورنا</span>
                    </p>
                    {/* <!--news-title--> */}
                    <h5 className='news-title'>
                      چگونه یک عطر ایده آل پیدا کنیم ؟
                    </h5>
                    {/* <!--news-text--> */}
                    <p className='news-text'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان روزنامه و مجله در ستون و سط
                    </p>
                  </div>
                </div>
              </a>
              {/* <!--box--> */}
              <a href='#' className='news-box-link'>
                <div className='news-box'>
                  {/* <!--img-wrapper--> */}
                  <div className='news-img-wrapper'>
                    <img src={news02} alt='img' className='news-img' />
                  </div>
                  {/* <!--caption--> */}
                  <div className='news-caption'>
                    {/* <!--date-and-writer-name--> */}
                    <p className='news-date-and-owner'>
                      <span className='news-date'>12 فروردین ,1401</span>
                      از
                      <span className='news-owner'>سورنا</span>
                    </p>
                    {/* <!--news-title--> */}
                    <h5 className='news-title'>
                      چگونه یک عطر ایده آل پیدا کنیم ؟
                    </h5>
                    {/* <!--news-text--> */}
                    <p className='news-text'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان روزنامه و مجله در ستون و سط
                    </p>
                  </div>
                </div>
              </a>
              {/* <!--box--> */}
              <a href='#' className='news-box-link'>
                <div className='news-box'>
                  {/* <!--img-wrapper--> */}
                  <div class='news-img-wrapper'>
                    <img src={news03} alt='img' className='news-img' />
                  </div>
                  {/* <!--caption--> */}
                  <div className='news-caption'>
                    {/* <!--date-and-writer-name--> */}
                    <p className='news-date-and-owner'>
                      <span className='news-date'>12 فروردین ,1401</span>
                      از
                      <span className='news-owner'>سورنا</span>
                    </p>
                    {/* <!--news-title--> */}
                    <h5 className='news-title'>
                      چگونه یک عطر ایده آل پیدا کنیم ؟
                    </h5>
                    {/* <!--news-text--> */}
                    <p className='news-text'>
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان روزنامه و مجله در ستون و سط
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default News;
