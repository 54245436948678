

import categ01 from '../../assets/images/21.jpeg'
import categ02 from '../../assets/images/22.jpg'
import categ03 from '../../assets/images/14.jpeg'
import categ04 from '../../assets/images/19.jpg'

const Category = () => {
  return (
    <div>
      {/* <!--============category-section==============--> */}
      <section className='category-section'>
        {/* <!--setting-container--> */}
        <div className='container'>
          {/* <!--Main-wrapper--> */}
          <div className='category-boxes-wrapper'>
            {/* <!--right-wrapper--> */}
            <div className='category-right-wrapper'>
              {/* <!--wrapper--> */}
              <div className='category-side-wrapper'>
                {/* <!--category-text-box--> */}
                <div className='category-text-box'>
                  {/* <!--text--> */}
                  <h3 className='category-text-h3'>
                    به فروشگاه پروتئین های ورزشی خوش آمدید
                    {/* <!--sub-text--> */}
                    <span className='category-sub-text-h3'>
                      اولین فروشگاه برندهای اصل ایران
                    </span>
                  </h3>
                  {/* <!--link--> */}
                  <a href='/shop' className='see-btn-v3'>
                    مشاهده همه برندها
                  </a>
                </div>

                {/* <!---box--> */}
                <div className='category-box category-box-v1'>
                  {/* <!--img--> */}
                  <img
                    src={categ01}
                    alt='img'
                    className='category-box-img category-box-img-1'
                  />
                  {/* <!--caption--> */}
                  <div className='category-caption-box'>
                    {/* <!--text--> */}
                    <p className='cateogry-box-text'>برند بی سی ان</p>
                    {/* <!--see-btn--> */}
                    <a href='/' className='see-btn-v2'>
                      مشاهده
                    </a>
                  </div>
                </div>
              </div>
              {/* <!--wrapper--> */}
              <div className='category-side-wrapper'>
                {/* <!---box--> */}
                <div className='category-box category-box-v1'>
                  {/* <!--img--> */}
                  <img
                    src={categ02}
                    alt='img'
                    className='category-box-img category-box-img-1'
                  />
                  {/* <!--caption--> */}
                  <div className='category-caption-box'>
                    {/* <!--text--> */}
                    <p className='cateogry-box-text'>برند بادی اتک </p>
                    {/* <!--see-btn--> */}
                    <a href='/' className='see-btn-v2'>
                      مشاهده
                    </a>
                  </div>
                </div>
                {/* <!---box--> */}
                <div className='category-box category-box-v1'>
                  {/* <!--img--> */}
                  <img
                    src={categ03}
                    alt='img'
                    className='category-box-img category-box-img-1'
                  />
                  {/* <!--caption--> */}
                  <div className='category-caption-box'>
                    {/* <!--text--> */}
                    <p className='cateogry-box-text'>برند الیمپ </p>
                    {/* <!--see-btn--> */}
                    <a href='/' className='see-btn-v2'>
                      مشاهده
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--left-wrapper--> */}
            <div className='category-left-wrapper'>
              {/* <!---box--> */}
              <div className='category-box category-box-v2'>
                {/* <!--img--> */}
                <img
                  src={categ04}
                  alt='img'
                  className='category-box-img category-box-img-1'
                />
                {/* <!--caption--> */}
                <div className='category-caption-box'>
                  {/* <!--text--> */}
                  <p className='cateogry-box-text'>برند اکستریفیت </p>
                  {/* <!--see-btn--> */}
                  <a href='/' className='see-btn-v2'>
                    مشاهده
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default Category;
