import logo from '../../assets/images/navigation-logo.png'
const HeaderNav = () => {
  return (
    <div>
      {/* <!--=======Header============--> */}
      <header class='header header-fixed'>
        {/* <!--=======setting-container======--> */}
        <div class='container'>
          {/* <!--====navigation=====--> */}
          <nav class='navigation navigation-2'>
            <input type='checkbox' id='check' class='nav-mobile-input' hidden />
            {/* <!--LOGO--> */}
            <a href='./home.html' class='navigation-log-link'>
              <div class='navigation-logo'>
                {/* <!--img--> */}
                <img src={logo} alt='img' class='navigation-logo-content' />
              </div>
            </a>
            {/* <!--menu--> */}
            <ul class='navigation-menu'>
              {/* <!--item--> */}
              <li class='navigation-menu-li'>
                <a href='./home.html' class='navigation-menu-link'>
                  خانه
                </a>
              </li>
              <li class='navigation-menu-li'>
                <a href='./shop.html' class='navigation-menu-link'>
                  فروشگاه
                </a>
              </li>
              <li class='navigation-menu-li'>
                <a href='#' class='navigation-menu-link'>
                  اخبار
                </a>
              </li>
              <li class='navigation-menu-li'>
                <a href='#' class='navigation-menu-link'>
                  درباره ی ما
                </a>
              </li>
              {/* <!--input-for-controlling-the-funtionality--> */}
              <input
                hidden
                type='checkbox'
                class='open-abale-search-box-input'
                id='open-abale-search-box-input'
              />
              {/* <!--icon--> */}
              {/* <!--search-box-wrapper--> */}
              <div class='navigation-search-box-wrapper'>
                {/* <!--search-box--> */}
                <input
                  type='text'
                  name='search'
                  class='navigation-search-box-input'
                  placeholder='دنبال چی میگردی ؟'
                />
                {/* <!--label--> */}
                <label
                  for='open-abale-search-box-input'
                  class='open-abale-search-box-label'
                >
                  {/* <!--search-icon--> */}
                  <i class='fa-solid fa-search'></i>
                </label>
              </div>
            </ul>
            {/* <!--navigation-links-wrapper--> */}
            <div class='navigation-links-wrapper'>
              {/* <!--link--> */}
              <a
                href='./login.html'
                class='navigation-link navigation-signin-link'
              >
                ورود
              </a>
              {/* <!--link--> */}
              <a
                href='./register.html'
                class='navigation-link navigation-signup-link'
              >
                ثبت نام
              </a>
            </div>
            {/* <!--hamburger-icon--> */}
            <label for='check' class='nav-mobile-label nav-open-icon-wrapper'>
              <i class='fa-solid fa-bars nav-open-icon'></i>
            </label>
            {/* <!--============mobile-navigation===========--> */}
            <div class='mobile-navigation-wrapper height-100vh'>
              {/* <!--close-icon--> */}
              <label
                for='check'
                class='nav-mobile-label nav-close-icon-wrapper'
              >
                <i class='fa-solid fa-xmark nav-close-icon'></i>
              </label>
              <div class='mobile-nav-links-btn-wrapper'>
                {/* <!--login-btn--> */}
                <a href='#' class='nav-mobile-login-link'>
                  ورود
                </a>
                {/* <!--shop-btn--> */}
                <a href='#' class='nav-link nav-mobile-shop-link'>
                  <i class='fa-solid fa-bag-shopping'></i>
                </a>
              </div>
              {/* <!--mobile-navigation--> */}
              <ul class='mobile-navigation'>
                {/* <!--item--> */}
                <li class='mobile-nav-item'>
                  <a href='#' class='mobile-nav-link mobil-active-link'>
                    خانه
                  </a>
                </li>
                {/* <!--item--> */}
                <li class='mobile-nav-item'>
                  <a href='#' class='mobile-nav-link'>
                    فروشگاه
                  </a>
                </li>
                {/* <!--item--> */}
                <li class='mobile-nav-item'>
                  <a href='#' class='mobile-nav-link'>
                    درباره ی ما
                  </a>
                </li>
                {/* <!--item--> */}
                <li class='mobile-nav-item'>
                  <a href='#' class='mobile-nav-link'>
                    مقالات
                  </a>
                </li>
                {/* <!--item--> */}
                <li class='mobile-nav-item'>
                  <a href='#' class='mobile-nav-link'>
                    ارتباط با ما
                  </a>
                </li>
                {/* <!--item--> */}
                <li class='mobile-nav-item  mobile-nav-search-box-item'>
                  {/* <!--search-box--> */}
                  <div class='search_box'>
                    <input type='checkbox' id='nav-check' hidden />
                    <div class='search_box_content'>
                      <label for='nav-check' class='search_icon'>
                        <i class='fa fa-search'></i>
                      </label>
                      <input
                        type='text'
                        class='search_box_input'
                        placeholder='دنبال چی میگردی ؟'
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* <!--search--> */}
          </nav>
        </div>
      </header>
    </div>
  )
}

export default HeaderNav
