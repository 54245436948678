import { Fragment } from 'react'
import { Outlet, Link } from 'react-router-dom'
import '../../assets/font-style/fonts.css'
import '../navigation/navigation.css'

import Logo from '../../assets/images/navigation-logo.png'

const Navigation = () => {
  return (
    <Fragment>
      <header className='header'>
        <div className='container'>
          {/* <!--====navigation=====--> */}
          <nav className='navigation'>
            {/* <!---background-light---> */}
            <div className='background-light background-light-navigation'></div>
            <input
              type='checkbox'
              id='check'
              className='nav-mobile-input'
              hidden
            />
            {/* <!--logo--> */}
            <a href='product' className='navigation-log-link'>
              <div className='navigation-logo'>
                {/* <!--img--> */}
                <img src={Logo} alt='img' className='navigation-logo-content' />
              </div>
            </a>
            {/* <!--menu--> */}
            <ul className='navigation-menu'>
              {/* <!--item--> */}
              <li className='navigation-menu-li'>
                <a href='/' className='navigation-menu-link'>
                  خانه
                </a>
              </li>
              <li className='navigation-menu-li'>
                <a href='/shop' className='navigation-menu-link'>
                  فروشگاه
                </a>
              </li>
              <li className='navigation-menu-li'>
                <a href='/blog' className='navigation-menu-link'>
                  محصولات
                </a>
              </li>
              <li className='navigation-menu-li'>
                <a href='/dashboard' className='navigation-menu-link'>
                  داشبورد
                </a>
              </li>
              <li className='navigation-menu-li'>
                <a href='/about-us' className='navigation-menu-link'>
                  درباره ی ما
                </a>
              </li>
              <li className='navigation-menu-li'>
                <a href='/blog' className='navigation-menu-link'>
                  بلاگ
                </a>
              </li>
              {/* <!--input-for-controlling-the-funtionality--> */}
              <input
                hidden
                type='checkbox'
                className='open-abale-search-box-input'
                id='open-abale-search-box-input'
              />
              {/* <!--icon--> */}
              {/* <!--search-box-wrapper--> */}
              <div className='navigation-search-box-wrapper'>
                {/* <!--search-box--> */}
                <input
                  type='text'
                  name='search'
                  className='navigation-search-box-input'
                  placeholder='دنبال چی میگردی ؟'
                />
                {/* <!--label--> */}
                <label
                  for='open-abale-search-box-input'
                  className='open-abale-search-box-label'
                >
                  {/* <!--search-icon--> */}
                  <i className='fa-solid fa-search'></i>
                </label>
              </div>
            </ul>
            {/* <!--navigation-links-wrapper--> */}
            <div className='navigation-links-wrapper'>
              {/* <!--link--> */}
              <a
                href='./login.html'
                className='navigation-link navigation-signin-link'
              >
                ورود
              </a>
              {/* <!--link--> */}
              <a
                href='./register.html'
                className='navigation-link navigation-signup-link'
              >
                ثبت نام
              </a>
            </div>
            {/* <!--hamburger-icon--> */}
            <label
              for='check'
              className='nav-mobile-label nav-open-icon-wrapper'
            >
              <i className='fa-solid fa-bars nav-open-icon'></i>
            </label>
            {/* <!--============mobile-navigation===========--> */}
            <div className='mobile-navigation-wrapper'>
              {/* <!--close-icon--> */}
              <label
                for='check'
                className='nav-mobile-label nav-close-icon-wrapper'
              >
                <i className='fa-solid fa-xmark nav-close-icon'></i>
              </label>
              <div className='mobile-nav-links-btn-wrapper'>
                {/* <!--login-btn--> */}
                <a href='#' className='nav-mobile-login-link'>
                  ورود
                </a>
                {/* <!--shop-btn--> */}
                <a href='#' className='nav-link nav-mobile-shop-link'>
                  <i className='fa-solid fa-bag-shopping'></i>
                </a>
              </div>
              {/* <!--mobile-navigation--> */}
              <ul className='mobile-navigation'>
                {/* <!--item--> */}
                <li className='mobile-nav-item'>
                  <a href='#' className='mobile-nav-link mobil-active-link'>
                    خانه
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='mobile-nav-item'>
                  <a href='#' className='mobile-nav-link'>
                    فروشگاه
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='mobile-nav-item'>
                  <a href='#' className='mobile-nav-link'>
                    درباره ی ما
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='mobile-nav-item'>
                  <a href='#' className='mobile-nav-link'>
                    مقالات
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='mobile-nav-item'>
                  <a href='#' className='mobile-nav-link'>
                    ارتباط با ما
                  </a>
                </li>
                {/* <!--item--> */}
                <li className='mobile-nav-item  mobile-nav-search-box-item'>
                  {/* <!--search-box--> */}
                  <div className='search_box'>
                    <input type='checkbox' id='nav-check' hidden />
                    <div className='search_box_content'>
                      <label for='nav-check' className='search_icon'>
                        <i className='fa fa-search'></i>
                      </label>
                      <input
                        type='text'
                        className='search_box_input'
                        placeholder='دنبال چی میگردی ؟'
                      />
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            {/* <!--search--> */}
          </nav>
        </div>
      </header>
      <Outlet />
    </Fragment>
  )
}

export default Navigation
