

import banner01 from '../../assets/images/24.png'
import banner02 from '../../assets/images/25.png'

const SpecialSell = () =>{

    return (
      <div>
        {/* <!--============special-section=============--> */}
        <section class='special-section'>
          {/* <!---background-light---> */}
          <div class='background-light background-light-special'></div>
          {/* <!--setting-container--> */}
          <div class='container'>
            {/* <!---banner--> */}
            <div class='special-banner special-banner-v1'>
              {/* <!--text-box--> */}
              <div class='special-banner-text-box'>
                {/* <!--title--> */}
                <h4 class='h4-title special-h4-title special-h4-title-v1 text-align-left margin-bottom-small'>
                  برند اپ نوتریشن
                </h4>
                {/* <!--text--> */}
                <p class='special-text margin-bottom-small'>
                  برای دیدن جدیدترین محصولات برند اپ نوتریشن کلیک کنید
                </p>
                <div class='special-btn-wrapper-v1'>
                  {/* <!--btn--> */}
                  <a
                    href='/'
                    class='btn btn-white-text-color btn-black-background '
                  >
                    مشاهده
                  </a>
                </div>
              </div>
              {/* <!--img--> */}
              <div class='special-banner-img'>
                {/* <!--content--> */}
                <img
                  src={banner01}
                  alt='img'
                  class='special-banner-img-content'
                />
              </div>
            </div>
            {/* <!---banner--> */}
            <div class='special-banner special-banner-v2'>
              {/* <!--img--> */}
              <div class='special-banner-img special-banner-img-v2'>
                {/* <!--content--> */}
                <img
                  src={banner02}
                  alt='img'
                  class='special-banner-img-content'
                />
              </div>
              {/* <!--text-box--> */}
              <div class='special-banner-text-box'>
                {/* <!--title--> */}
                <h4 class='h4-title special-h4-title special-h4-title-v2  margin-bottom-small'>
                  برند اپتیموم نوتریشن
                </h4>
                {/* <!--text--> */}
                <p class='special-text margin-bottom-small'>
                  برای دیدن جدیدترین محصولات برند اپتیموم نوتریشن کلیک کنید
                </p>
                <div class='special-btn-wrapper-v2'>
                  {/* <!--btn--> */}
                  <a
                    href='#'
                    class='btn btn-white-text-color btn-black-background '
                  >
                    مشاهده
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )

}



export default SpecialSell;