

import img39 from '../../assets/images/cat-39.png'

const ProductSection = () => {
  return (
    <div className='product-section-v2'>
      {/* <!--==========product-section==========--> */}
      <section>
        {/* <!--wrapper--> */}
        <div className='product-content-wrapper-v3'>
          {/* <!--main-img--> */}
          <div className='product-main-img-wrapper'>
            {/* <!--img--> */}
            <img src={img39} alt='img' className='product-img-v3' />
          </div>
          {/* <!--sub-img---> */}
          <div className='product-sub-img-wrapper'>
            {/* <!--img-box--> */}
            <div className='sub-img-box'>
              {/* <!--img--> */}
              <img
                src={img39}
                alt='img'
                className='product-sub-img product-sub-img-1'
              />
            </div>
            {/* <!--img-box--> */}
            <div className='sub-img-box'>
              {/* <!--img--> */}
              <img
                src={img39}
                alt='img'
                className='product-sub-img product-sub-img-2'
              />
            </div>
            {/* <!--img-box--> */}
            <div className='sub-img-box'>
              {/* <!--img--> */}
              <img
                src={img39}
                alt='img'
                className='product-sub-img product-sub-img-3'
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProductSection
