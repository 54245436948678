import { FaInstagram, FaTwitter, FaLinkedin, FaFacebook } from 'react-icons/fa'
import Logo from "../../assets/images/navigation-logo.png";



const Footer = () => {
  return (
    <div>
      <footer className='footer'>
        <div className='container'>
          {/* <!--content-wrapper--> */}
          <div className='footer-content-wrapper'>
            {/* <!--footer-right-side--> */}
            <div className='footer-right'>
              {/* <!--footer-right-column--> */}
              <div className='footer-right-column'>
                {/* logo */}
                  <div className='footer-logo-wrapper'>
                    <img src={Logo} alt="img" className='footer-logo' />
                  </div>
                {/* footer-paragraph */}
                <p className='footer-paragraph'>
                  فروشگاه ما با ارائه‌ی بهترین و باکیفیت‌ترین مکمل‌های بدنسازی از معتبرترین برندهای جهانی، همراهی مطمئن برای شما در مسیر دستیابی به اهداف تناسب اندام و سلامت است. ما با تعهد به کیفیت و رضایت مشتری، همواره در تلاشیم تا محصولات متنوعی را برای نیازهای مختلف ورزشی شما فراهم کنیم. با اعتماد به فروشگاه ما، به بهبود عملکرد ورزشی خود سرعت ببخشید و به سطح بالاتری از موفقیت دست یابید.
                </p>
                {/* social-icons-wrapper */}
                <div className='social-icons-wrapper'>
                  {/* instagram */}
                  <a href='#' className='social-icon-link'>
                    <FaInstagram className='social-icon' />
                  </a>
                  {/* twitter */}
                  <a href='#' className='social-icon-link'>
                    <FaTwitter className='social-icon' />
                  </a>
                  {/* linkedin */}
                  <a href='#' className='social-icon-link'>
                    <FaLinkedin className='social-icon' />
                  </a>
                  {/* facebook */}
                  <a href='#' className='social-icon-link'>
                    <FaFacebook className='social-icon' />
                  </a>
                </div>
              </div>
              {/* <!--footer-right-column--> */}
              <div className='footer-right-column'>
                {/* title */}
                <h5 className='footer-column-title'>دسترسی های سریع</h5>
                {/* links-list */}
                <ul className='footer-list'>
                  {/* item */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      خانه
                    </a>
                  </li>
                  {/* item */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      فروشگاه
                    </a>
                  </li>
                  {/* item */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      درباره ی ما
                    </a>
                  </li>
                  {/* item */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      مقالات
                    </a>
                  </li>
                  {/* item */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      ارتباط با ما
                    </a>
                  </li>
                </ul>
              </div>
              {/* <!--footer-right-column--> */}
              <div className='footer-right-column'>
                {/* <!--title--> */}
                <h5 className='footer-column-title contact-us-column'>
                  خدمات مشتریان
                </h5>
                {/* <!--links-list--> */}
                <ul className='footer-list'>
                  {/* <!--item--> */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      رهگیری سفارشات
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      راهنمای خرید از وب سایت
                    </a>
                  </li>
                  {/* <!--item--> */}
                  <li className='footer-item'>
                    <a href='#' className='footer-link footer-list-link'>
                      شرایط و قوانین خرید از فروشگاه
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!--footer-left-side--> */}
            <div class='footer-left'>
              {/* <!--left-column--> */}
              <div class='footer-left-column'>
                {/* <!--title--> */}
                <h5 class='footer-column-title contact-us-column'>
                  ایمل خود را
                </h5>
                {/* <!--main-text--> */}
                <p class='footer-column-main-text'>
                  برای جدید ترین اخبار و بروزرسانی ها وارد کنید
                </p>
                {/* <!--footer-form-wrapper--> */}
                <div class='footer-form-wrapper'>
                  {/* <!--footer-form--> */}
                  <form class='email-input-wrapper'>
                    <input
                      type='email'
                      class='email-input'
                      placeholder='name@gmail.com'
                    />
                    <button type='submit' class='email-subimt-btn'>
                      ارسال
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
