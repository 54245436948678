import { Outlet } from 'react-router-dom'

import HeaderBanner from '../../components/header-banner/header-banner.component'
import Category from '../../components/category/category.component'
import MiniShop from '../../components/mini-shop/mini-shop.component'
import SpecialSell from '../../components/special-sell/special-sell.component'
import HomeServices from '../../components/home-services/home-services.component'
import Brands from '../../components/brands/brands.component'
import News from '../../components/news/news.component'
import Footer from '../../components/footer/footer.component'

const Home = () => {
  return (
    <div>
      <Outlet />
      <div>
        <HeaderBanner />
        <main className='main'>
          <Category />
          <SpecialSell />
          <HomeServices />
          <MiniShop />
          <News />
          <Brands />
        </main>
        <Footer />
      </div>
    </div>
  )
}

export default Home
