import React, { useState } from "react";

import img01 from '../../assets/images/New/01.png'
import img02 from '../../assets/images/New/02.png'
import img03 from '../../assets/images/New/03.png'
import img04 from '../../assets/images/New/04.png'
import img05 from '../../assets/images/New/05.png'
import img06 from '../../assets/images/New/06.png'
import img07 from '../../assets/images/New/13.png'
import img08 from '../../assets/images/New/07.png'
import img09 from '../../assets/images/New/08.png'
import img10 from '../../assets/images/New/09.png'
import img11 from '../../assets/images/New/10.png'
import img12 from '../../assets/images/New/11.png'
import img13 from '../../assets/images/New/12.png'
import img14 from '../../assets/images/New/13.png'
import img15 from '../../assets/images/New/14.png'
import img16 from '../../assets/images/New/15.png'

import creatineBodyAttack from "../../assets/images/New/03.png";
import aminoNutriversum from "../../assets/images/New/02.png";
import wheyMutant from "../../assets/images/New/09.png";
import aminoMutant from "../../assets/images/New/12.png";
import citrullineNutriversum from "../../assets/images/New/14.png";
import Carnitine from "../../assets/images/New/13.png";
import TBooster from "../../assets/images/New/024.png";
import FatBurner from "../../assets/images/New/025.png";
import Glutamin from "../../assets/images/New/026.png";
import GainerMutant from "../../assets/images/New/027.png";

// محصولات



const NewstProducts = [
  {
    id: 1,
    name: "کراتین Body Attack",
    price: "1,200,000 تومان",
    imageUrl: creatineBodyAttack,
    status: "available",
  },
  {
    id: 2,
    name: "آمینو قرصی Nutriversum",
    price: "1,350,000 تومان",
    imageUrl: aminoNutriversum,
    status: "notAvailable",
  },
  {
    id: 3,
    name: "پروتئین Whey Mutant",
    price: "3,100,000 تومان",
    imageUrl: wheyMutant,
    status: "available",
  },
  {
    id: 4,
    name: "آمینو قرصی Mutant",
    price: "1,200,000 تومان",
    imageUrl: aminoMutant,
    status: "available",
  },
  {
    id: 5,
    name: "سیترولین L-Citrulin Nutriversum",
    price: "900,000 تومان",
    imageUrl: citrullineNutriversum,
    status: "notAvailable",
  },
  {
    id: 6,
    name: "الکارنیتین L-Carnitine",
    price: "900,000 تومان",
    imageUrl: Carnitine,
    status: "notAvailable",
  },
];


const PopularProducts = [
  {
    id: 1,
    name: "تی-بوستر LEGION NUTRITION",
    price: "800,000 تومان",
    imageUrl: TBooster,
    status: "available",
  },
  {
    id: 2,
    name: "سیترولین L-Citrulin Nutriversum",
    price: "900,000 تومان",
    imageUrl: citrullineNutriversum,
    status: "notAvailable",
  },
  {
    id: 3,
    name: "پروتئین Whey Mutant",
    price: "3,100,000 تومان",
    imageUrl: wheyMutant,
    status: "available",
  },
  {
    id: 4,
    name: "آمینو قرصی Mutant",
    price: "1,200,000 تومان",
    imageUrl: aminoMutant,
    status: "available",
  },
  {
    id: 5,
    name: "چربی سوز LEGION NUTRITION",
    price: "800,000 تومان",
    imageUrl: FatBurner,
    status: "available",
  },
  {
    id: 6,
    name: "گلوتامین V-Shape",
    price: "1,300,000 تومان",
    imageUrl: Glutamin,
    status: "notAvailable",
  },
];



const mostSaleProducts = [
  {
    id: 1,
    name: "گینر مس Mutant",
    price: "2,700,000 تومان",
    imageUrl: GainerMutant,
    status: "available",
  },
  {
    id: 2,
    name: "سیترولین L-Citrulin Nutriversum",
    price: "900,000 تومان",
    imageUrl: citrullineNutriversum,
    status: "notAvailable",
  },
  {
    id: 3,
    name: "پروتئین Whey Mutant",
    price: "3,100,000 تومان",
    imageUrl: wheyMutant,
    status: "available",
  },
  {
    id: 4,
    name: "آمینو قرصی Mutant",
    price: "1,200,000 تومان",
    imageUrl: aminoMutant,
    status: "available",
  },
  {
    id: 5,
    name: "چربی سوز LEGION NUTRITION",
    price: "800,000 تومان",
    imageUrl: FatBurner,
    status: "available",
  },
  {
    id: 6,
    name: "گلوتامین V-Shape",
    price: "1,300,000 تومان",
    imageUrl: Glutamin,
    status: "notAvailable",
  },
];




const MiniShop = () => {
  return (
    <div>
      {/* <!--==========best-products=============--> */}
      <section className='suggestions-section'>
        {/* <!--background-lights--> */}
        <div className='background-light suggestion-background-light'></div>
        {/* <!--setting-container--> */}
        <div className='container'>
          {/* <!--===content-wrapper====--> */}
          <div className='suggestions-content-wrapper'>
            {/* <!--input-for-contorolling-the-functionality--> */}
            {/* <!--input--> */}
            <input
              type='radio'
              id='newest'
              name='suggestions'
              className='suggestions-input'
              defaultChecked
            />
            <input
              type='radio'
              id='most-popular'
              name='suggestions'
              className='suggestions-input'
            />
            <input
              type='radio'
              id='most-seller'
              name='suggestions'
              className='suggestions-input'
            />
            {/* <!--======labeles-wrapper======--> */}
            <div className='suggestions-buttons-wrapper'>
              <label
                htmlFor='newest'
                className='suggestions-btn suggestion-newest-label suggestions-label'
              >
                جدید ترین ها
              </label>
              <label
                htmlFor='most-popular'
                className='suggestions-btn suggestion-most-popular-label suggestions-label'
              >
                محبوب ترین ها
              </label>
              <label
                htmlFor='most-seller'
                className='suggestions-btn suggestion-most-seller-label suggestions-label'
              >
                پرفروش ترین ها
              </label>
            </div>
            {/* <!--==============product-main-wrapper===============--> */}
            <div className='product-main-wrapper'>
              <section className='product-section newest-product-section'>
                <div className='product-content-wrapper-v2'>
                  {NewstProducts.map((product) => (
                    <a href='#' className='product-link' key={product.id}>
                      <div className='product-box'>
                        {/* وضعیت موجودی محصول */}
                        {product.status === 'notAvailable' && (
                          <div className='finish-product-mark'>
                            <p className='finish-product-text'>اتمام !</p>
                          </div>
                        )}
                        {/* بخش تصویر محصول */}
                        <div className='product-img-wrapper'>
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            className='product-img'
                          />
                        </div>
                        {/* بخش توضیحات محصول */}
                        <div className='product-caption'>
                          <p className='product-name'>{product.name}</p>
                          <p className='product-price'>{product.price}</p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                {/* <!--===view-btn-wrapper=====--> */}
                <div className='view-btn-wrapper'>
                  {/* <!--view-btn--> */}
                  <a href='./shop.html' className='view-btn'>
                    <span className='view-btn-dot'>.</span>
                    <span className='view-btn-text'>دیدن همه</span>
                    <span className='view-btn-icon-span'>
                      <i className='fa-solid fa-arrow-right view-btn-icon'></i>
                    </span>
                  </a>
                </div>
              </section>
              <section className='product-section most-popular-product-section'>
                <div className='product-content-wrapper-v2'>
                  {PopularProducts.map((product) => (
                    <a href='#' className='product-link' key={product.id}>
                      <div className='product-box'>
                        {/* وضعیت موجودی محصول */}
                        {product.status === 'notAvailable' && (
                          <div className='finish-product-mark'>
                            <p className='finish-product-text'>اتمام !</p>
                          </div>
                        )}
                        {/* بخش تصویر محصول */}
                        <div className='product-img-wrapper'>
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            className='product-img'
                          />
                        </div>
                        {/* بخش توضیحات محصول */}
                        <div className='product-caption'>
                          <p className='product-name'>{product.name}</p>
                          <p className='product-price'>{product.price}</p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                {/* <!--===view-btn-wrapper=====--> */}
                <div className='view-btn-wrapper'>
                  {/* <!--view-btn--> */}
                  <a href='./shop.html' className='view-btn'>
                    <span className='view-btn-dot'>.</span>
                    <span className='view-btn-text'>دیدن همه</span>
                    <span className='view-btn-icon-span'>
                      <i className='fa-solid fa-arrow-right view-btn-icon'></i>
                    </span>
                  </a>
                </div>
              </section>
              <section className='product-section most-seller-product-section'>
                <div className='product-content-wrapper-v2'>
                  {mostSaleProducts.map((product) => (
                    <a href='#' className='product-link' key={product.id}>
                      <div className='product-box'>
                        {/* وضعیت موجودی محصول */}
                        {product.status === 'notAvailable' && (
                          <div className='finish-product-mark'>
                            <p className='finish-product-text'>اتمام !</p>
                          </div>
                        )}
                        {/* بخش تصویر محصول */}
                        <div className='product-img-wrapper'>
                          <img
                            src={product.imageUrl}
                            alt={product.name}
                            className='product-img'
                          />
                        </div>
                        {/* بخش توضیحات محصول */}
                        <div className='product-caption'>
                          <p className='product-name'>{product.name}</p>
                          <p className='product-price'>{product.price}</p>
                        </div>
                      </div>
                    </a>
                  ))}
                </div>
                {/* <!--===view-btn-wrapper=====--> */}
                <div className='view-btn-wrapper'>
                  {/* <!--view-btn--> */}
                  <a href='./shop.html' className='view-btn'>
                    <span className='view-btn-dot'>.</span>
                    <span className='view-btn-text'>دیدن همه</span>
                    <span className='view-btn-icon-span'>
                      <i className='fa-solid fa-arrow-right view-btn-icon'></i>
                    </span>
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
};

export default MiniShop;
