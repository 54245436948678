const CaptionSection = () => {
  return (
    <div className='caption-section'>
      {/* <!--==========caption-section==========--> */}
      <section>
        {/* <!--main-wrapper--> */}
        <div className='caption-content-wrapper'>
          {/* <!--title--> */}
          <h1 className='product-name-v2'>
            <span className='sub-text-v2'>
              عطری که بوی زیبایی و جذابیت را با خود به ارمغان می‌آورد
            </span>
            <span className='main-text-v2'>عطر MARLY LAYTON</span>
          </h1>
          {/* <!--rate-box--> */}
          <div className='rate-box'>
            {/* <!--rate-icon--> */}
            <i className='fa-solid fa-star rate-icon'></i>
            {/* <!--rate-number--> */}
            <p className='rate-text'>
              <span className='rate-number'>8.5</span>.
              <span className='rate-participle'>150 مشارکت کننده</span>
            </p>
          </div>
          {/* <!--product-deatails-text--> */}
          <p className='product-paragraph'>
            عطر Marly Layton یک عطر گرم و شیرین است که برای فصل پاییز و زمستان
            طراحی شده است، اما به دلیل طبیعت گرم و دلپذیرش، نیز در بهار نیز
            بسیار مورد توجه قرار می‌گیرد. این عطر برای هر دو جنسیت مناسب است و
            با ترکیباتی از نت‌های گرم مانند وانیل، دارچین، مشک و گل رز، همچنین
            ترکیبات خنکی مانند لیمو و نت‌های میوه‌ای مانند سیب و مشکی‌گوجه، یک
            ترکیب مطلوب و جذاب از عناصر شیرین و تند را ارائه می‌دهد. این ترکیب
            منحصر به فرد از عطر Layton احساسی دلنشین و یک شیوهٔ بی‌نظیر را به هر
            کسی که از آن استفاده می‌کند، ارائه می‌دهد.
          </p>

          {/* <!--price--> */}
          <p className='procut-price'>12,000,000 تومان</p>

          {/* <!--order-number-wrapper--> */}
          <div className='order-number-wrapper'>
            {/* <!--less-button--> */}
            <button className='order-button order-less-button'>-</button>
            {/* <!--order-number--> */}
            <p className='order-number'>1</p>
            {/* <!--more-button--> */}
            <button className='order-button order-more-button'>+</button>
          </div>

          {/* <!--buttons-wrapper--> */}
          <div className='product-buttons-wrapper'>
            {/* <!--add-btn--> */}
            <button className='product-main-btn add-btn'>افزودن به سبد</button>
            {/* <!--buy--> */}
            <button className='product-main-btn buy-btn'>خرید</button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default CaptionSection
