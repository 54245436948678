// icon

import { FaTruck, FaBullseye, FaHeadphones } from 'react-icons/fa'


// =-------------------=


const HomeServices = () => {
  return (
    <div>
      {/* <!--==========Benefits-section===========--> */}
      <section className='benefits-section'>
        {/* <!--setting-container--> */}
        <div className='benefits-section'>
          {/* <!--setting-container--> */}
          <div className='container'>
            {/* <!--main-wrapper--> */}
            <div className='benefits-content-wrapper'>
              {/* <!--box--> */}
              <div className='benefit-box'>
                {/* <!--icon--> */}

                <FaTruck className='fa-solid fa-truck-fast benefit-icon' />

                {/* <!--text-box--> */}
                <div className='benefit-text-box'>
                  <h4 className='benefit-title'>ارسال به سراسر کشور</h4>
                  <p className='benefit-caption'>تحویل 5 الی 7 روز کاری</p>
                </div>
              </div>
              {/* <!--box--> */}
              <div className='benefit-box'>
                {/* <!--icon--> */}

                <FaBullseye className='fa-solid fa-bullseye benefit-icon' />

                {/* <!--text-box--> */}
                <div className='benefit-text-box'>
                  <h4 className='benefit-title'>ضمانت پس از فروش</h4>
                  <p className='benefit-caption'>گارانتی 3 تا 12 ماهه</p>
                </div>
              </div>
              {/* <!--box--> */}
              <div className='benefit-box'>
                {/* <!--icon--> */}
                
                <FaHeadphones className='fa-solid fa-headphones-simple benefit-icon' />

                {/* <!--text-box--> */}
                <div className='benefit-text-box'>
                  <h4 className='benefit-title'>پشتیبانی انلاین</h4>
                  <p className='benefit-caption'>
                    همکاران ما 24/7 در خدمت شما هستند
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default HomeServices
