// img

import img01 from '../../assets/images/company-logo-1.png'
import img02 from '../../assets/images/company-logo-2.png'
import img03 from '../../assets/images/company-logo-3.png'
import img04 from '../../assets/images/company-logo-4.png'
import img05 from '../../assets/images/company-logo-5.png'
import img06 from '../../assets/images/company-logo-6.png'
import img07 from '../../assets/images/company-logo-7.png'

const Brands = () => {
  return (
    <div>
      {/* <!--===========news-section=========--> */}
      <section className='company-section'>
        {/* <!--setting-container--> */}
        <div className='container'>
          {/* <!--content-wrapper--> */}
          <div className='company-content-wrapper'>
            {/* <!--company-img--> */}
            <a href='/' className='company-img-link'>
              <img src={img01} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img02} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img03} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img04} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img05} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img06} alt='img' className='company-img' />
            </a>
            <a href='/' className='company-img-link'>
              {/* <!--company-img--> */}
              <img src={img07} alt='img' className='company-img' />
            </a>
          </div>
        </div>
      </section>
    </div>
  )
};

export default Brands;
