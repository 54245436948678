import { Route, Routes } from 'react-router-dom'
import Navigation from './routes/navigation/navigation.component'

import logo from './logo.svg'
import './App.css'
import Home from './routes/home/home.component'
import ProductDeatails from './routes/product-deatails/product-deatails.component'

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Navigation />}>
        <Route index element={<Home />} />
      </Route>
      <Route path='product' element={<ProductDeatails />} />
    </Routes>
  )
}

export default App
