import { Outlet } from "react-router-dom";

import ProductSection from "../../components/product-section/product-section.component";
import CaptionSection from "../../components/product-caption/product-caption.component";
import Footer from "../../components/footer/footer.component";
import HeaderNav from "../../components/header-nav/header-nav.component";

const ProductDeatails = () => {
    return (
        <div>
            <HeaderNav />
            <main className="main product-main-section">
                <ProductSection />
                <CaptionSection />
            </main>
            <Footer />
        </div>
    )
}

export default ProductDeatails;